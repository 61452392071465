* {
  font-size: 1.2rem;
  box-sizing: border-box;
}

body {
  margin: 0 0.5rem;
  text-align: center;
}

h2{
  margin-top: 3.5rem;
  margin-bottom: 0.3rem;
}


.inputRow {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1%;
    row-gap: 1rem;
    justify-content: center;
    width: 99%;
    margin: 1rem auto;
}

.inputRow .wrong,
.inputRow .wrong * {
    background-color: red !important;
}

.inputRow .valueless,
.inputRow .valueless * {
    background-color: rgb(240, 240, 240);
}

.inputRow>div.kai,
.inputRow>div.uri {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1%;
    justify-content: center;
    overflow: hidden;
}

.inputRow>div.etc {
    display: flex;
    overflow: hidden;
}

.inputRow .ryou,
.inputRow .yen {
    flex: 1 0 49%;
    gap: 1%;
}

.inputRow .tanka {
    flex: 1 0 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
}

.inputRow .ryou>div,
.inputRow .yen>div,
.inputRow .tanka>div,
.inputRow .memo>div {
    display: flex;
}

.inputRow .ryou label,
.inputRow .yen label,
.inputRow .memo label {
    display: block;
    text-align: center;
    font-size: 0.6rem;
    color: gray;
}

.inputRow .tanka label {
    font-size: 0.6rem;
    color: gray;
}


.inputRow input {
    padding-top: 0;
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
    text-align: right;
    width: 100%;
}

.inputRow .memo {
    text-align: left;
    flex-grow: 1;
}

.inputRow>.operation {
    flex-grow: 0;
}

.inputRow button {
    font-size: 0.9rem;
}

.inputRow .unit {
    font-size: 0.6rem;
    color: gray;
    align-self: end;
}


.inputNow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inputNow>* {
    flex: 1 0 100%;
}

.inputNow>div {
    display: flex;
    justify-content: center;
}

.inputNow input {
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
    text-align: right;
    width: 40%;
}

.inputNow .unit {
    font-size: 0.6rem;
    color: gray;
    align-self: end;
}

/* レスポンシブ対応 ************************************/

.inputRow>* {
    flex: 1 0 32%;
}

.inputNow label {
    display: block;
    text-align: center;
    font-size: 0.6rem;
    color: gray;
}


@media screen and (max-width:700px) {
    .inputRow>* {
        flex: 1 0 100%;
    }
}
.simulator label {
    font-size: 0.6rem;
    color: gray;
    flex: 1 0 100%;
}

.simulator input {
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
    text-align: right;
    width: 40%;
}

.simulator .unit {
    font-size: 0.6rem;
    color: gray;
    align-self: end;
}

.simulator .offer {
    background-color: #f0fff0;
    margin: 2rem 0;
    text-align: center;
}

.simulator .policy {
    background-color: #00b300;
    color: white;
}

.simulator summary {
    cursor: pointer;
}

.simulator .detail>summary {
    background-color: #ccffcc;
    color: #00b300;
}

.simulator .detail>summary:hover {
    background-color: #00b300;
    color: #ccffcc;
}

.simulator .operation {
    margin: 1.5rem 0;
    font-weight: bold;
}

.simulator .operation>span {
    font-weight: bold;
    font-size: 2rem;
}

.simulator .offer table {
    margin: 0 auto 1rem auto;
    border-collapse: collapse;
}

.simulator .offer table th,
.simulator .offer table td {
    border: solid 1px #00b300;
}

.simulator .offer table th {
    font-size: 0.8rem;
}

.simulator .offer table tr:first-child th:first-child {
    border: none;
}

.simulator .offer table th:nth-child(2) {
    width: 15rem;
}

.simulator .offer table th:nth-child(3) {
    width: 15rem;
}

.simulator .operationKae,
.simulator .operationUre {
    margin: 1.5rem;
}

.simulator .operationKae>div,
.simulator .operationUre>div {
    display: flex;
    width: 50%;
    gap: 1%;
    margin: 0.2rem auto;
    border-bottom: solid 1px #00b300;
    line-height: 1.1;
    align-items: flex-end;
}

.simulator .label {
    flex: 0 0 auto;
    font-size: 0.9rem;
    color: gray;
}

.simulator .value {
    flex: 1 1 auto;
    text-align: right;
    overflow: hidden;
}

.simulator .operation .and {
    font-size: 0.9rem;
    margin: 0 0.5rem;
}

/* レスポンシブ対応 ************************************/



@media screen and (max-width:700px) {

    .simulator .operationKae>div,
    .simulator .operationUre>div {
        width: 95%;
    }
}
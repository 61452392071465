.finance .title {
    margin-top: 0;
    color: gray;
    line-height: 1;
}

.finance .list {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
}

.finance .list>div {
    display: flex;
    width: 50%;
    gap: 1%;
    margin: 0.2rem auto;
    border-bottom: solid 1px #00b300;
    line-height: 1.1;
    align-items: flex-end;
}

.finance .label {
    flex: 0 0 auto;
    font-size: 0.9rem;
    color: gray;
}

.finance .value {
    flex: 1 1 auto;
    text-align: right;
    overflow: hidden;
}


/* レスポンシブ対応 ************************************/

@media screen and (max-width:700px) {

    .finance .list>div {
        width: 95%;
    }
}
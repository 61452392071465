.diffFinance .title {
    margin-top: 1.5rem;
    color: gray;
    line-height: 1;
}

.diffFinance .list {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
}

.diffFinance .list>div {
    display: flex;
    width: 75%;
    gap: 1%;
    margin: 0.2rem auto;
    border-bottom: solid 1px #00b300;
    line-height: 1.1;
    align-items: flex-end;
}

.diffFinance .label {
    flex: 0 0 auto;
    font-size: 0.9rem;
    text-align: left;
    color: gray;
    display: flex;
}

.diffFinance .value {
    flex: 1 1 50%;
    text-align: right;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}

.diffFinance .value>* {
    overflow: hidden;
}

.diffFinance .diff {
    flex: 1 1 30%;
    text-align: right;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}

.diffFinance .diff>* {
    overflow: hidden;
}
.diffFinance .unit {
    overflow: visible;
}

.diffFinance .positive {
    color: blue;
}

.diffFinance .negative {
    color: red;
}

/* レスポンシブ対応 ************************************/

@media screen and (max-width:700px) {

    .diffFinance .list>div {
        width: 95%;
    }
}